import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql, Link } from "gatsby" // to query for image data

import Layout from "../components/layout"
import SEO from "../components/seo"

const Home = () => {
  const data = useStaticQuery(graphql`
  query {
    headshot: file(relativePath: { eq: "swh-headshot-2021.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    westham: file(relativePath: { eq: "west-ham.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    unsettledLand: file(relativePath: { eq: "books/book-unsettled-land.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`)
  return (
    <Layout pageTitle="Sam W. Haynes" pageSubtitle="U.S. History Professor & Author">
      <SEO title="Sam W. Haynes" />
      <section id="intro" className="row align-items-center">
        <div className="col-md-4 text-center">
          <h2 style={{ textAlign: 'center' }}><b />
            <Img className="img-responsive" fluid={data.headshot.childImageSharp.fluid} alt="" />
          </h2>
        </div>
        <div className="col-md-8">
          <p>I've taught at the University of Texas at Arlington since 1993. I received tenure in 1998 and was promoted to the rank of full professor in 2010. Since 2008, I've served as the director of the <a href="http://www.uta.edu/southwesternstudies/index.html" target="_blank" rel="noreferrer">Center for Greater Southwestern Studies</a>.</p>
          <p>While working on my Ph. D. in the 1980s, I taught U.S. and European History at the Kinkaid School in Houston, Texas.</p>
        </div>
      </section>
      <section id="interests" className="row">
        <div className="col-md-12 m-auto">
          <hr />
          <h2 className="text-center pb-3"><b>Scholarship and Research</b></h2>
        </div>
        <div className="col-md-7 m-auto">
          <p>For the past few years my research has focused on 19th century Texas and the American Southwest. My most recent book, <Link to="/works/">Unsettled Land: From Revolution to Republic, the Struggle for Texas (2022)</Link>, seeks to offer a new interpretation of the founding of early modern Texas. Although “larger-than-life” figures like Sam Houston, David Crockett, and William Barret Travis—all white males--dominate the traditional narrative, Texas was one of the most racially diverse regions on the North American continent on the eve of the rebellion against Mexico in 1835. This book adopts a wide lens, to show how Anglos, Mexicans, Native Americans, and people of African descent struggled to navigate the upheavals that rocked the region in the early decades of the nineteenth century. I'm currently working on an article that looks at the Daughters of the Republic of Texas and the role of gender in shaping the state's historical memory.</p>
          <p>I'm also a historian of the Early American Republic, with a particular interest in understanding the United States in the context of a wider world. My third book, Unfinished Revolution: The Early American Republic in A British World (2010), examined the extent to which Great Britain shaped American life in the Jacksonian era. Earlier in my career, I authored a short biography of James K. Polk, James K. Polk and the Expansionist Impulse (1996), which focused on the United States' efforts to establish a continental empire in the 1840s.</p>
        </div>
        <div className="col-md-5">
          <Img className="img-responsive" fluid={data.unsettledLand.childImageSharp.fluid} alt="" />
        </div>
      </section>
      <section className="row" id="teaching">
        <div className="col-md-12 m-auto">
          <hr />
          <h2 className="text-center pb-3"><b>Teaching</b></h2>
          <p>At the undergraduate level, I regularly teach Texas History to 1850 (HIST 3363), both as a face-to-face class and on-line. I also teach, on a fairly frequent basis, the New Nation (HIST 3323), Hollywood and the West (HIST 3370), U.S. History and Film (HIST 3344), and Presidential Personality (HIST 3359). At the graduate level, I teach colloquia and seminars in my fields of research, as well as Introduction to U.S. Historiography (HIST 5340).</p>
        </div>
      </section>
      <section className="row align-items-center" id="personal">
        <div className="col-md-12 m-auto">
          <hr />
          <h2 className="text-center pb-3"><b>Personal</b></h2>
        </div>
        <div className="col-md-10 m-auto">
          <p>I was born in Shreveport, Louisiana but spent much of my early life in England (London) and Switzerland. I came back to the United States for college, receiving my B.A. from Columbia University and my Ph.D. from the University of Houston. I’m a lifelong supporter of West Ham United Football Club.</p>
        </div>
        <div className="col-md-2">
          <Img className="img-responsive m-auto" style={{ maxWidth: '200px' }} fluid={data.westham.childImageSharp.fluid} alt="" />
        </div>
      </section>
    </Layout>
  )
}

export default Home
